<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import staffService from '@/api/staffService'

export default {
  page: {
    title: 'Users Management',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  computed: {
    onEditId() {
      return this.$route.query.id;
    },
  },
  data() {
    return {
      title: 'Users Management',
      items: [
        {
          text: 'Customer',
        },
        {
          text: 'Customer',
          active: true,
        },
      ],
      companies: [
        { value: null, text: 'Please select an option' },
        { value: 'Berdaya', text: 'Berdaya' },
        { value: 'Boon Hua', text: 'Boon Hua' },
        { value: 'Emicro', text: 'Emicro' },
        { value: 'Pahang Motors', text: 'Pahang Motors' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: 'age',
      sortDesc: false,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'position',
          sortable: true,
        },
        {
          key: 'office',
          sortable: true,
        },
        {
          key: 'age',
          sortable: true,
        },
        {
          key: 'date',
          sortable: true,
        },
        {
          key: 'salary',
          sortable: true,
        },
      ],
      form: {
        name: '',
        email: '',
        contact: '',
        company: '',
        role_id: '',
      },
      rolesOptions: [
        { value: 1, text: 'Admin' },
        { value: 2, text: 'Agent' },
        { value: 3, text: 'Verifier' },
        { value: 4, text: 'Authorizer' },
        { value: 5, text: 'Installer' },
        { value: 6, text: 'Lender' }
      ]
    };
  },
  methods: {
    async submitStaff(method = 'store', id) {
      staffService[method](this.form, id)
        .then(() => {
          Swal.fire({
            title: 'Success!',
            text: 'Staff has been added',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            this.$router.push('/users');
          });
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  mounted() {
    if (this.onEditId) {
      staffService.show(this.onEditId).then((res) => {
        this.form = res;
      });
    }
  },
  middleware: 'authentication',
};
</script>

<template>
  <Layout>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">User s Management</h4>
        </div>
        <div class="card-body">
          <div>
            <div class="row">
              <div class="col-md-5">
                <b-form-group label="Email" label-for="email" class="mb-3">
                  <b-form-input v-model="form.email" id="email" type="text"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-5">
                <b-form-group label="Password" label-for="password" class="mb-3">
                  <b-form-input v-model="form.password" id="password" type="password"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-2">
                <b-form-group label="Role" label-for="role_id" class="mb-3">
                  <b-form-select
                    v-model="form.role_id"
                    style="
                      padding: 0.47rem 0.75rem;
                      font-size: 0.9rem;
                      width: 100%;
                      height: 100%;
                    "
                    :options="rolesOptions"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group
                  label="Fullname"
                  label-for="name"
                  class="mb-3"
                >
                  <b-form-input v-model="form.name" id="name" type="text"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group label="Phone Number" label-for="contact" class="mb-3">
                  <b-form-input v-model="form.contact" id="contact" type="text"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group label="Company" label-for="company" class="mb-3">
                  <b-form-select
                    style="
                      padding: 0.47rem 0.75rem;
                      font-size: 0.9rem;
                      width: 100%;
                      height: 100%;
                    "
                    :disabled="false" v-model="form.company"
                    :options="companies"
                    
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button v-if="onEditId" @click="submitStaff('update', onEditId)" class="btn btn-primary" >
                  Save
                </button>
                <button v-else @click="submitStaff('store')" class="btn btn-primary" >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
